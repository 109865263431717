import React from "react";
import Logo from "../Images/cbcloader.gif";
import { BarLoader } from "react-spinners";

export default function Loader() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img alt="Logo" width={350} src={Logo} />

      <br />
      {/* <BarLoader color="#1950a3" /> */}
    </div>
  );
}
