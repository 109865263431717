import React from "react";
import Logo from "../Images/cbclogo.png";
import "./Footer.css";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  let navigate = useNavigate();
  return (
    <>
      <footer className="footer">
        <div className="footer__addr">
          <h1 className="footer__logo">
            <img src={Logo} width={100} />
          </h1>

          <address>
            25, 1st Main Road, Bharatha Ratna Rajiv Gandhi Nagar, Pudur,
            Ambattur, Chennai - 600053
            <br />
          </address>
        </div>
        <ul className="footer__nav">
          <li className="nav__item">
            <h2 className="nav__title">Links</h2>
            <ul className="nav__ul">
              <li>
                <a href="">About</a>
              </li>
              <li>
                <a href="">Contact</a>
              </li>
              <li>
                <a href="">Services</a>
              </li>
            </ul>
          </li>
          <li className="nav__item nav__item--extra">
            <h2 className="nav__title">Technology</h2>
            <ul className="nav__ul nav__ul--extra">
              <li>
                <a href="">E-Commerce Solutions</a>
              </li>
              <li>
                <a href="">ERP Solutions</a>
              </li>
              <li>
                <a href="">CRM Solutions</a>
              </li>
              <li>
                <a href="">AI and Machine Learning </a>
              </li>
              <li>
                <a href="">Cybersecurity Solutions</a>
              </li>
              <li>
                <a href="">Digital Marketing Services</a>
              </li>
            </ul>
          </li>
          <li className="nav__item">
            <h2 className="nav__title">Legal</h2>
            <ul className="nav__ul">
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="/privacypolicy">Terms of Use</a>
              </li>
              <li>
                <a href="">Sitemap</a>
              </li>
            </ul>
          </li>
        </ul>
        <div className="legal">
          <p>© 2024 CBC. All rights reserved.</p>
        </div>
      </footer>
    </>
  );
}
